@import 'styleguide';

.navigation-component {
    flex-direction: row !important;
    z-index: 1200 !important;
    justify-content: center;
    position: fixed !important;
    top: auto !important;
}

.nav-svg {
    stroke: $bright-gray;
}

.active .nav-svg {
    stroke: $aqua-marine;
}

.active .navigation-text-header {
    color: $aqua-marine !important;
}

.navigation-container-header-buttons {
    align-items: center !important;
    flex: content;
}

.navigation-text-header {
    color: $bright-gray !important;
    font-family: Montserrat, sans-serif !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: 1.5px !important;
}

@media screen and (min-width: 960px) {
    .navigation-component {
        order: 1;
        height: 76px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05) !important;
    }

    .navigation-header-content {
        width: 860px;
        display: flex;
    }

    .navigation-wechselgott-image {
        display: flex;
        align-items: center;
    }

    .navigation-wechselgott-image > div > svg {
        height: 32px;
    }

    .container-header-buttons {
        justify-content: flex-end !important;
    }

    .navigation-header-item {
        flex-direction: row;
        margin-left: 40px !important;
    }

    .navigation-header-item-text {
        margin-left: 15px !important;
    }

    .navigation-container-header-buttons {
        justify-content: flex-end !important;
    }
}

@media (max-width: 959px) {
    .navigation-header-content {
        flex-grow: 1;
    }

    .navigation-wechselgott-image {
        display: none;
    }

    .navigation-header-item-text {
        display: none !important;
    }

    .navigation-component {
        bottom: 0;
    }

    .navigation-container-header-buttons {
        // display: grid !important;
        grid-template-columns: auto auto auto !important;

        > div {
            display: flex;
            justify-content: center;
        }
    }
}

// try to check for iPhone X and above (nodge and bottom)
.nav-ios-style {
    @media only screen and (max-width: 420px) and (min-height: 811px) {
        padding-bottom: 1.5rem;
    }
}
