@import 'font';
@import 'styleguide';
@import 'styles-basic';
@import 'styles-responsive';

:root {
    --weird-green: #3fd298;
    --tealish: #1dbab1;
    --aqua-marine: #3ccfc7;
    --white: #ffffff;
    --black: #000000;
    --very-light-gray: #f2f2f2;
    --light-gray: #dadada;
    --bright-gray: #bfbfbf;
    --middle-gray: #acacac;
    --gray: #808080;
    --dark-gray: #4c4c4c;
    --orange-red: #ff1a1a;

    --app-background: #fafafa;
}
