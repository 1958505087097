@import 'styleguide';

.signup-progressbar-container {
    width: 100%;
    align-self: center;
    margin: 0 80px 0 32px;
}

.signup-progressbar-line {
    position: relative;
    margin-top: 6px;
    height: 2px !important;
    border-radius: 1px;
    background-color: $light-gray !important;
    z-index: 1;
}

.signup-progressbar-point-container {
    position: relative;
    z-index: 2;
    width: 100%;
}

.signup-progressbar-point {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 2px;
    width: 5px;
    background-color: $white !important;
    margin-top: -2px;
    margin-left: -2px;
}

.signup-header {
    width: 100vw;
    display: flex;
    position: fixed;
    justify-content: center;
    top: 0;
}

@media screen and (min-width: 960px) {
    .signup-header-button {
        margin-left: 15px !important;
    }

    .signup-progressbar-container {
        margin-left: 18px;
    }
}
