@import 'styleguide';

a:active {
    outline: none !important;
}

.text-info-content {
    font-family: Muli, sans-serif !important;
    font-size: 24px !important;
    font-weight: 800 !important;
    line-height: normal !important;
    text-align: center;
    color: $tealish !important;
}

.text-content-gray {
    font-family: Muli, sans-serif !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    color: $dark-gray !important;
}

.text-login-info {
    font-family: Muli, sans-serif !important;
    font-size: 14px !important;
    color: $gray !important;
}

.text-profile-name {
    font-family: Muli, sans-serif !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: $dark-gray !important;
}

.text-profile-info {
    font-family: Muli, sans-serif !important;
    font-size: 14px !important;
    color: $gray !important;
}

.text-uppercase {
    font-family: Montserrat, sans-serif !important;
    margin-bottom: 8px !important;
    text-transform: uppercase;
    color: $gray !important;
    font-size: 10px !important;
    letter-spacing: 1px !important;
}

.text-uppercase-spacing {
    font-family: Montserrat, sans-serif !important;
    text-transform: uppercase;
    font-size: 12px !important;
    letter-spacing: 1.5px !important;

    &.gray {
        color: $bright-gray !important;
    }
}

.main-image {
    z-index: -10;
    position: absolute;
    left: 0;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    object-position: 20% 40%;
}

.buttons-start-info {
    justify-content: center;
}

.shadow-container {
    border-radius: 5px !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
}

/*Signup*/

.signup-background {
    flex-grow: 1;
    display: grid;
    grid-template-rows: 1fr 95px;
    align-content: center;
}

.signup-image {
    z-index: -10;
    position: absolute;
    left: 0;
    height: calc(100vh - 230px - 48px);
    width: 100vw;
    object-fit: cover;
}

.box-width {
    width: 600px;
    max-width: 100vw;
    justify-self: center;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
    display: none;
}

.body-app {
    position: fixed;
    width: 100%;
    align-content: flex-start;
    background-color: $app-background;
    grid-template-rows: auto 1fr;
}

.background-image {
    background-image: url(/img/background/wolken-bg-smartphone.jpg);
    background-position: bottom;
    background-size: cover;
}

.button-cursor {
    cursor: pointer;
}

.entry-field-housenumber > div {
    margin-right: -15px;
}

.search-field-text > input {
    font-family: Muli, sans-serif;
}

.button-container {
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
}

.button-container-middle {
    display: flex;
    justify-content: center;
}

/* No Account/Fixie */

/* ------------------- */

.cancellation-dialog > div > div {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    margin: 0px;

    > iframe {
        height: 100%;
    }
}
