@import 'styleguide';

.welcome-component {
    display: grid;
    grid-template-rows: 0 50% 50%;
    height: 100%;
    position: fixed;
}

.welcome-img {
    height: 60px;
    vertical-align: middle;
}

.welcome-cloud {
    max-width: 600px;
    object-fit: contain;
    background-image: radial-gradient(
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0) 60%
    );
    padding: 60px 80px;
    margin: -60px 0;
    align-self: center;
}

.welcome-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
}

.welcome-container-buttons {
    padding-left: 25px;
    padding-right: 25px;
    align-items: center;
}

.welcome-button-login {
    justify-content: flex-end;
    padding-bottom: 25px;
}
