$weird-green: #3fd298;
$tealish: #1dbab1;
$aqua-marine: #3ccfc7;

$blue-gray: #fdfefe;
$blue-gray2: #e5e7e9;

$white: #ffffff;
$very-light-gray: #f2f2f2;
$light-gray: #dadada;
$bright-gray: #bfbfbf;
$middle-gray: #acacac;
$gray: #818181;
$dark-gray: #4c4c4c;
$light-black: #202020;
$almost-black: #121212;
$black: #000;

$light-orange: #ffe5ce;
$senf: #ffc757;
$flamenco: #ff7e0d;
$bittersweet: #ff6666;
$orange-red: #ff1a1a;

$app-background: #fafafa;
$app-background-dark: $almost-black;
$highlight-primary: #fff;
$highlight-secondary: #fff;
