@import 'styleguide';

.signupStep-component {
    position: fixed;
    height: calc(100% - 42px);
    margin-top: 42px;
    flex-grow: 1;
}

.signupStep-info-container {
    display: flex;
    flex-direction: column;
}

.signupStep-content {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100vw;
    justify-content: center;
    display: flex;
}

.signupStep-img {
    height: 50px;
    vertical-align: middle;
}

.signup-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3px 25px;
}

input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
}

/** start react-phone-number-input (don't delete!) **/
.PhoneInputInput {
    font-size: 1rem;
    border: none;
    margin-left: 10px;
    outline: none !important;
}
.PhoneInputCountryIconImg {
    vertical-align: super;
}
.PhoneInputCountrySelectArrow {
    margin-left: 8px !important;
    width: 0.4em !important;
    height: 0.4em !important;
}
.PhoneInput--focus > .PhoneInputCountry > .PhoneInputCountrySelectArrow {
    border-bottom: 1px solid #03b2cb !important;
    border-right: 1px solid #03b2cb !important;
}
.PhoneInputCountrySelectArrow {
    border-bottom: 1px solid $dark-gray !important;
    border-right: 1px solid $dark-gray !important;
}
/** end react-phone-number-input **/

@media screen and (min-width: 960px) {
    .signupStep-info-container {
        padding: 75px 25px;
    }
}

@media (max-width: 959px) {
    .signupStep-info-container {
        padding: 25px;
    }
}
