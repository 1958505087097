@media screen and (min-width: 960px) {
    .background-image {
        background-image: url(/img/background/wolken-bg-original.jpg);
    }

    .body-app {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        order: 2;
        justify-content: center;
        height: calc(100% - 76px);
        top: 76px;
        display: grid;
    }

    .body-content {
        width: 860px;
    }

    .container-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    //noinspection CssUnusedSymbol
    .button-standard {
        width: 325px !important;
    }

    .button-container {
        justify-content: flex-end;
    }
}

@media (max-width: 959px) {
    .body-content {
        margin-top: 60px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        width: 100%;
    }

    .body-app {
        height: calc(100% - 50px);
        display: flex;

        // NOTE: This disables text selection everywhere.
        // For list items or views where a user might want to copy stuff we should add it again.
        // We need this so the iOS web view doesn't break when the user selects something. Hacky I know...
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        //-moz-user-select: all; /* Old versions of Firefox */
        //-ms-user-select: all; /* Internet Explorer/Edge */
        //user-select: all; /* Non-prefixed version, currently
        // supported by Chrome, Opera and Firefox */
    }

    .header-content {
        flex-grow: 1;
    }

    .container-padding {
        padding-right: 25px;
        padding-left: 25px;
    }

    a::after {
        display: none;
        /*content: "pseudo block!";*/
    }
    a:hover::after {
        display: inline;
    }

    .button-container {
        justify-content: center;
    }
}
