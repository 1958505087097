@font-face {
    font-family: 'Muli';
    font-display: auto;
    src: url('/fonts/Muli-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Muli';
    font-display: auto;
    src: url('/fonts/Muli-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Muli';
    font-display: auto;
    src: url('/fonts/Muli-Bold.ttf') format('truetype');
    font-weight: 900;
}

@font-face {
    font-family: 'Muli';
    font-display: auto;
    src: url('/fonts/Muli-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: 'Rubik';
    font-display: auto;
    src: url('/fonts/Rubik-Regular-Minified.ttf') format('truetype');
}

@font-face {
    font-family: 'Rubik';
    font-display: auto;
    src: url('/fonts/Rubik-Light-Minified.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Montserrat';
    font-display: auto;
    src: url('/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-display: auto;
    src: url('/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
}
